










































import { Component, Watch, Vue } from 'vue-property-decorator';
import policyStore, { PolicyStore, Policy } from '@/repository/kontent/stores/policyStore';
import { reactive } from '@vue/composition-api';
import I18n, { Languages } from '@/i18n/index';
import { i18n } from 'dateformat';

@Component({
  components: {},
})
export default class Index extends Vue {
  private policyDetailData = reactive(new PolicyStore());
  private i18n = I18n;
  @Watch('$route')
  private async created() {
    await this.getPolicyDeatail();
  }

  private get html() {
    return this.policyDetailData.list[0]?.html.value;
  }

  private async getPolicyDeatail() {
    this.policyDetailData.initQuery();
    this.policyDetailData.setFilter('system.name', '情報セキュリティポリシー');
    await this.policyDetailData.restore();
  }
}
