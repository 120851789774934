import Vue from 'vue';
import { reactive } from '@vue/composition-api';
import Store from './base/store';
import { Policy } from "../models/policy";


class PolicyStore extends Store<Policy>{
  constructor() {
    super('policy');
  }
}

const x = new Store<Policy>('policy');
x.restore();

export { PolicyStore, Policy };
export default reactive(x);
